import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import newBaseApi from "../../Services/newBaseApi";
import "./css/Anuncio.css";
import Tab from "./tabs";
import DefaultUser from "../../Assets/defaultuser.jpeg"; // Imagem padrão caso não tenha foto
import Footer from "../../components/Footer/Footer";
import Rating from "react-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";

export default function Anuncio() {
  const params = useParams();
  const [data, setData] = useState([]);
  const [avaliacoes, setAvaliacoes] = useState([]);

  useEffect(() => {
    fixLoad();
    
    async function loadExibir() {
      try {
        const response = await newBaseApi.get(`/anunciante/public-data/${params.id}`);
        setData(response.data);
      } catch (error) {
        console.error("Erro ao carregar dados do anunciante:", error);
      }
    }

    async function loadAvaliacoes() {
      try {
        const response = await newBaseApi.get(`/anunciante/avaliacoes/${params.idAnunciante}`);
        setAvaliacoes(response.data.data || []);
      } catch (error) {
        console.error("Erro ao carregar avaliações:", error);
      }
    }

    loadExibir();
    loadAvaliacoes();
  }, [params.id, params.idAnunciante]);

  const fixLoad = () => {
    document.querySelector("html").style = "scroll-behavior: unset;";
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  };

  const roundToHalf = (num) => Math.round(num * 2) / 2;

  return (
    <>
      <div className="container-anuncio">
        <div className="main-anuncio">
          <div className="imagem-anuncio">
            {data.logo ? (
              <img src={data.logo} alt="Logo do Anunciante" />
            ) : (
              <img src={DefaultUser} alt="Logo Padrão" />
            )}
          </div>
          <h1>{data.nome}</h1>
          <p>{data.descricao || "Descrição não disponível."}</p>
          {data.media_avaliacoes !== undefined && (
            <div className="media-avaliacoes">
              <Rating
                emptySymbol={<FontAwesomeIcon icon={faStarRegular} className="star-icon" />}
                fullSymbol={<FontAwesomeIcon icon={faStarSolid} className="star-icon filled" />}
                initialRating={roundToHalf(data.media_avaliacoes)}
                readonly
              />
            </div>
          )}
        </div>
        

        <div className="container-tabs">
          <Tab data={data} />
        </div>

        {/* Seção de Avaliações */}
        <div className="avaliacoes-container">
          <h2>Avaliações</h2>
          {avaliacoes.length > 0 ? (
            <ul className="lista-avaliacoes">
              {avaliacoes.map((avaliacao, index) => (
                <li key={index} className="avaliacao-item">
                  {/* Foto do consumidor */}
                  <div className="avaliacao-avatar">
                    <img
                      src={avaliacao.foto_consumidor ? avaliacao.foto_consumidor : DefaultUser}
                      alt={avaliacao.consumidor_name}
                    />
                  </div>

                  {/* Conteúdo da avaliação */}
                  <div className="avaliacao-conteudo">
                    <span className="avaliacao-nome">{avaliacao.consumidor_name}</span>
                    <div className="rating-stars">
                      <Rating
                        emptySymbol={<FontAwesomeIcon icon={faStarRegular} className="star-icon" />}
                        fullSymbol={<FontAwesomeIcon icon={faStarSolid} className="star-icon filled" />}
                        initialRating={roundToHalf(avaliacao.nota)}
                        readonly
                      />
                    </div>
                    <p className="avaliacao-comentario">{avaliacao.comentario}</p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="sem-avaliacoes">Nenhuma avaliação disponível.</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
