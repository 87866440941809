import Footer from "../../components/Footer/Footer";
import React from "react";

export default function PoliticaDePrivacidadeApp() {
  return (
    <>
      <div className="privacy-policy">
        <h1>POLÍTICA DE PRIVACIDADE</h1>
        <p>
          LUP AGÊNCIA DE PUBLICIDADE LTDA., inscrita no CNPJ sob
          31.436.839/0001-09, com endereço à Rua Cândido Mader, nº 74, Juvevê,
          Curitiba – PR, CEP: 80.030-300 (“LUP” ou “Nós”), é uma empresa que,
          por meio do website{" "}
          <a href="https://lup.com.br/">https://lup.com.br/</a> (“Website”) e
          aplicativo móvel exclusivo para fornecedores/prestadores de serviços
          (“App”), criou um sistema diferenciado para, como intermediadora, unir
          e atender as necessidades de pessoas que desejam organizar um evento e
          fornecedores/prestadores de serviços da área, auxiliando, assim, na
          realização de momentos inesquecíveis, e, prezando pela transparência,
          conformidade à legislação e contratação de um relacionamento de
          confiança.
        </p>
        <p>
          A presente Política de Privacidade (“Política de Privacidade”) traz
          informações e esclarecimentos importantes sobre o tratamento de dados
          coletados (i) em nosso Website/App e/ou (ii) por meio de qualquer
          outra forma de comunicação e comercialização de nossos
          produtos/serviços.
        </p>
        <p>
          Nesta Política, demonstraremos:
          <ul>
            <li>Quais dos seus dados coletamos;</li>
            <li>Qual(is) a(s) finalidade(s) de tal coleta; e</li>
            <li>
              Como tais dados serão protegidos, enquanto estiverem conosco.
            </li>
          </ul>
        </p>
        <p>
          Desde logo, ressaltamos que toda a nossa atuação se dá sempre
          observando o disposto na Lei Geral de Proteção de Dados Pessoais – Lei
          Federal n. 13.709/18 (“LGPD”).
        </p>

        <h2>TERMOS DEFINIDOS</h2>
        <p>
          <strong>Consentimento</strong> – significa a autorização livre,
          informada e inequívoca que o Titular de Dados concede para o
          Tratamento de Dados, conforme Finalidade previamente estipulada. O
          Consentimento poderá ser revogado a qualquer momento. A revogação não
          cancela o tratamento realizado previamente.
        </p>
        <p>
          <strong>Dados</strong> – significam os dados, pessoais ou não, que
          identificam, fazem referência, descrevem, podem ser utilizados para
          entrar em contato com, ou podem ser vinculados, direta ou
          indiretamente, ao indivíduo a que se referem.
        </p>
        <p>
          <strong>Finalidade</strong> – significa o objetivo que pretendemos
          alcançar com o Tratamento de Dados.
        </p>
        <p>
          <strong>Titular de Dados</strong> – significa a pessoa física que terá
          os seus Dados Tratados por Nós, podendo se referir a potenciais
          clientes, fornecedores e prestadores de serviços pessoas físicas.
        </p>
        <p>
          <strong>Tratamento de Dados</strong> – significa a coleta, produção,
          recepção, classificação, utilização, acesso, reprodução, transmissão,
          distribuição, processamento, arquivamento, armazenamento, eliminação,
          avaliação ou controle da informação, comunicação, transferência,
          difusão ou extração dos Dados de pessoas físicas.
        </p>

        <h2>1. DOS DADOS COLETADOS E FINALIDADES</h2>
        <p>
          No desenvolvimento de nossas atividades, poderemos coletar os
          seguintes Dados:
          <ul>
            <li>
              <p>
                {" "}
                <strong>De Potenciais Clientes e Clientes:</strong> Nome
                completo, CPF, imagem, sexo, data de nascimento, município e
                estado.
              </p>
              <p>
                Finalidades – Cadastro no Website, contato e envio de
                informações, auxiliando na identicação e individualização do
                Potencial Cliente/Cliente, e, prestação dos serviços de
                intermediação ofertados pela LUP;
              </p>
              <p>
                Forma de Coleta – Diretamente, via Website{" "}
                <a href="https://lup.com.br/sign-up">
                  https://lup.com.br/sign-up
                </a>
                , mediante o fornecimento de tais informações pelo próprio
                Potencial Cliente/Cliente, ora Titular de Dados.
              </p>
              <p>
                Base Legal – Art. 7º, inc. I (consentimento) + inc. V (execução
                de contrato) + inc. IX (legítimo interesse).
              </p>
            </li>
            <li>
              <p>
                {" "}
                <strong>
                  De Fornecedores e Prestadores de Serviços Pessoas Físicas:
                </strong>{" "}
                Nome, CPF, telefone, celular, e-mail e endereço.
              </p>
              <p>
                Finalidades – Cadastro no Website/App, contato e envio de
                informações, auxiliando na identicação e individualização do
                Fornecedor/Prestador de Serviços, e, prestação dos serviços de
                intermediação ofertados pela LUP;
              </p>
              <p>
                Forma de Coleta – Diretamente, via App {" "}
                <a href="https://lup.com.br/app-links">
                  através deste link
                </a>
                , mediante o fornecimento de tais informações pelo
                próprio Prestador de Serviços/Fornecedor, ora Titular de Dados.
              </p>
              <p>
                Base Legal – Art. 7º, inc. I (consentimento) + inc. V (execução
                de contrato) + inc. IX (legítimo interesse).
              </p>
            </li>
            <li>
              <p>
                {" "}
                <strong>Para todos os casos:</strong> Dados de hardware do
                aparelho do Titular de Dados (modelo, fabricante, provedor de
                serviços de internet, sistema operacional), dados de acesso (log
                in/log out, horário, localização, IP e meio de acesso) e dados
                de cookies.
              </p>
              <p>
                Finalidades – Garantir o melhor funcionamento possível do
                Website/App e demais aplicações móveis da LUP, nos mais diversos
                dispositivos, bem como otimizar performance e desempenho,
                identicando as preferências do Titular de Dados, inclusive
                servindo para informar acerca de: (a) como os recursos do
                Website/App são visitados por terceiros; (b) qual é o
                comportamento do Titular de Dados; e (c) informações padrões de
                internet e de dispositivos.
              </p>
            </li>
          </ul>
        </p>
        <p>
          Cookies são arquivos de texto enviados quando do acesso ao
          Website/App. Eles armazenam padrões e histórico de navegação para
          reconhecer o Titular de Dados em futuras visitas, proporcionando uma
          experiência customizada com base em suas preferências. Além disso, o
          uso dos cookies permite que realizemos pesquisas e diagnósticos para
          melhorar o conteúdo e os produtos/serviços do Website/App, bem como
          proporciona ferramentas para impedir atividades fraudulentas.
        </p>
        <p>
          Caso o Titular de Dados deseje saber mais sobre cookies, inclusive
          como desativá-los, pode acessar https://www.allaboutcookies.org/ e/ou
          diretamente em seu navegador padrão, se Internet Explorer:
          <a href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies">
            https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies
          </a>
          ; se Mozilla Firefox:
          <a href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam">
            https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
          </a>
          ; se Google Chrome:
          <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR">
            https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR
          </a>
          .
        </p>
        <p>
          Sobre os Dados Coletados. Todos os Dados que coletamos são tratados
          como confidenciais e somente serão utilizados para a realização das
          finalidades descritas acima e/ou autorizadas pelo Titular de Dados.
          Ressalta-se que o Titular de Dados pode optar por não compartilhar
          determinadas informações conosco, conforme demonstrado no item
          “Cookies”, mas neste caso, esta decisão pode privá-lo de parte das
          funcionalidades que são proporcionadas pela LUP
        </p>

        <h2>2. DOS RESPONSÁVEIS PELO TRATAMENTO DOS DADOS NA LUP</h2>
        <p>
          O Tratamento é realizado:
          <ul>
            <li>
              Por Nós, LUP, já qualificada acima, ora Controladora, responsável
              por tomar as decisões referentes ao Tratamento dos Dados;
            </li>
            <li>
              Pelo Sr. Marcelo Eduardo Glauser, com e-mail de contato{" "}
              <a href="mailto:eng.comp@lup.com.br">eng.comp@lup.com.br</a>,
              Operador com a experiência necessária para o Tratamento de Dados e
              responsável por adotar as medidas de segurança cabíveis;
            </li>
            <li>
              Pelo Sr. Henrique Polinarski Cimbalista, com e-mail{" "}
              <a href="mailto:dados@lup.com.br">dados@lup.com.br</a>,
              Encarregado responsável por atuar como um canal de comunicação
              entre a LUP, o Titular de Dados e a Autoridade Nacional de Dados -
              ANPD.
            </li>
          </ul>
        </p>

        <h2>3. DO COMPARTILHAMENTO DE DADOS COM TERCEIROS</h2>
        <p>
          Nós jamais emprestamos, alugamos ou vendemos os Dados do Titular de
          Dados. No entanto, podemos compartilhá-los - quando realmente
          necessário para cumprimento das finalidades - com:
          <ul>
            <li>
              Parceiros, Prestadores de Serviços e Fornecedores: empresas
              responsáveis e que realizam o desenvolvimento e gestão do sistema
              de informação (e-mail, redes de telecomunicações, provedores e
              hospedagem), e, prestadores de serviços e fornecedores que atuam,
              direta ou indiretamente, para prover alguma de suas
              funcionalidades e aprimorar o atendimento ao Titular de Dados nos
              eventos, bem como empresas de contabilidade e advocacia, ou seja,
              todos aqueles vinculados contratualmente à LUP;
            </li>
            <li>
              Autoridades: entidades e órgãos da Administração Pública,
              inclusive regulatórios, para scalizações e controles relativos ao
              cumprimento das obrigações scais e civis e demais autoridades
              estatais e as equiparadas, inclusive judiciais e arbitrais, que
              têm direito ao acesso de dados pessoais por força de lei;
            </li>
            <li>
              Softwares: empresas responsáveis pelos softwares e demais
              tecnologias presentes na LUP com a nalidade de aprimorar a
              segurança, analisar e solucionar problemas técnicos e coibir
              fraudes; e
            </li>
            <li>
              Anunciantes/Redes de Publicidade/Marketing: empresas responsáveis
              pelo marketing e publicidade da LUP com a nalidade especíca de
              personalizar serviços e produtos consumidos pelo Titular de Dados,
              visando aprimorar suas experiências junto à LUP.
            </li>
          </ul>
        </p>
        <p>
          Portanto, o compartilhamento de Dados com terceiros busca atender o
          próprio interesse do Titular de Dados, tendo nalidades legítimas, nos
          limites aqui contidos. Ademais, prezamos por contratar parceiros,
          fornecedores e prestadores de serviços que se atentem e garantam a
          privacidade dos dados que eventualmente acessam. Sempre que possível,
          para cumprir obrigação legal, regulatória ou contratual,
          anonimizaremos os dados pessoais antes de referido compartilhamento.
        </p>

        <h2>4. DO ARMAZENAMENTO DE DADOS PELA LUP</h2>
        <p>
          Os Dados permanecerão retidos pelo tempo necessário para que Nós
          possamos cumprir com as Finalidades para as quais os coletamos, sendo
          o período mínimo de armazenamento de 05 (cinco) anos. Assim, todos os
          Dados coletados serão excluídos dos nossos servidores, ou
          anonimizados, quando: (a) não forem mais necessários para o
          fornecimento de nossos produtos/serviços; e/ou (b) solicitado pelo
          Titular de Dados, salvo eventual obrigação legal de retenção de dados
          ou necessidade para resguardo de nossos direitos.
        </p>

        <h2>5. TRANSFERÊNCIA INTERNACIONAL DE DADOS</h2>
        <p>
          Nós possuímos sede no Brasil e seguimos a legislação brasileira de
          proteção de dados. Não fazemos qualquer tipo de transferência direta
          dos Dados para outros países.
        </p>

        <h2>6. DA POLÍTICA DE SEGURANÇA DA LUP</h2>
        <p>
          Reconhecemos que somos responsáveis por cuidar dos Dados do Titular de
          Dados a que temos acesso e utilizá-los somente para as nalidades
          descritas nesta Política de Privacidade. Assim, visando garantir a
          manutenção dos Dados de forma segura, restrita e condencial, adotamos
          práticas de segurança e governança adequadas como:
          <ul>
            <li>
              Métodos padrões e de mercado para criptografar e mascarar os Dados
              coletados;
            </li>
            <li>
              Software de proteção contra acesso não autorizado aos sistemas;
            </li>
            <li>
              Autorização de acesso somente a pessoas previamente aprovadas pela
              LUP aos locais onde esta armazena as informações condenciais, bem
              como controle de modicação de dados;
            </li>
            <li>
              Celebração de documentos contratuais com os colaboradores e
              parceiros que têm acesso às informações, visando estabelecer a
              obrigação de manutenção do sigilo absoluto e condencialidade dos
              Dados acessados, sob pena de responsabilidade civil e penal, nos
              moldes da legislação brasileira.
            </li>
          </ul>
        </p>
        <p>
          Embora adotemos os melhores esforços para preservar a sua privacidade
          e proteger os seus Dados, a segurança total do Titular de Dados é algo
          que não depende apenas das nossas ações e processos.
        </p>
        <p>
          {" "}
          Desse modo, não podemos garantir que os Dados do Titular de Dados não
          serão interceptados ou acessados por meio de atos ilícitos, falhas de
          hardware ou software, entre outros fatores.
        </p>
        <p>
          {" "}
          Assim, caso o Titular de Dados identique algo que comprometa a
          segurança dos Dados este deve, imediatamente, nos avisar para que, na
          hipótese de ocorrer eventual incidente com os Dados que estejam,
          efetivamente, sob a nossa guarda, atuemos de forma ágil eficaz.
        </p>

        <h2>7. DOS DIREITOS DO TITULAR DE DADOS</h2>
        <p>
          O Titular de Dados pode requerer, a qualquer tempo, a conrmação,
          correção, modicação, portabilidade, eliminação, informações de
          compartilhamento e acesso a seus Dados, o que será analisado e
          ponderado por Nós para a tomada das medidas necessárias. Em
          cumprimento à legislação aplicável, no que diz respeito ao Tratamento
          de Dados, a LUP garante que o Titular de Dados poderá exercer os
          seguintes direitos:
          <ul>
            <li>Confirmação da existência de tratamento;</li>
            <li>Acesso aos Dados;</li>
            <li>Correção de Dados incompletos, inexatos ou desatualizados;</li>
            <li>
              Anonimização, bloqueio ou eliminação de Dados desnecessários,
              excessivos ou tratados em desconformidade;
            </li>
            <li>
              Portabilidade de Dados a outro fornecedor, mediante requisição
              expressa;
            </li>
            <li>
              A eliminação dos Dados tratados com consentimento, exceto quando a
              lei autorizar a manutenção destes por outro fundamento;
            </li>
            <li>
              Obtenção de informações sobre as entidades públicas ou privadas
              com as quais a LUP compartilhou seus Dados;
            </li>
            <li>
              A informação sobre a possibilidade de não fornecer o
              Consentimento, bem como sobre as consequências em caso de
              negativa; e
            </li>
            <li>A revogação do Consentimento</li>
          </ul>
        </p>

        <h2>8. DADOS DE MENORES</h2>
        <p>
          A utilização de nosso Website com a coleta e tratamento de dados de
          menores de idade, está condicionada ao fornecimento de consentimento
          especíco e em destaque concedido por pelo menos um dos pais ou pelo
          representante legal, visando, sempre, o melhor interesse do menor.
          <br />
          Quando houver a coleta intencional ou recepção de dados de menores, a
          LUP dará transparência acerca de seu tratamento em termos especícos,
          nos regulamentos de cada evento. <br />
          Excepcionalmente, em caso de ocorrência de segurança envolvendo
          menores de idade, poderá haver coleta de dados para contato com os
          responsáveis, bem como para sua proteção e melhor interesse, sem
          consentimento especíco.
        </p>

        <h2>9. LINKS DE TERCEIROS</h2>
        <p>
          Poderemos fornecer links para outros websites na internet visando
          melhorar a experiência de navegação do Titular de Dados. No entanto,
          não nos responsabilizamos pelos conteúdos de demais websites e/ou
          sobre a forma como estes coletam, processam e transferem os Dados do
          Titular de Dados. <br />
          Recomendamos que cada Titular de Dados consulte as respectivas
          políticas de privacidade de tais websites antes de fornecer seus
          Dados.
        </p>

        <h2>10. DO CONTATO DO TITULAR DE DADOS COM A LUP</h2>
        <p>
          Se o Titular de Dados desejar mais informações acerca do Tratamento de
          Dados, exercer seus direitos ou se tiver dúvidas, comentários ou
          sugestões relacionadas a esta Política de Privacidade, deverá entrar
          em contato com a LUP por meio do Encarregado Sr. Henrique Polinarski
          Cimbalista, pelo e-mail{" "}
          <a href="mailto:dados@lup.com.br">dados@lup.com.br</a>.
        </p>

        <h2>11. DAS EVENTUAIS MUDANÇAS NA POLÍTICA DE PRIVACIDADE</h2>
        <p>
          Nos reservamos o direito de atualizar ou modicar esta Política de
          Privacidade a qualquer momento para garantir o compromisso de
          transparência com o Titular de Dados. Desta forma, recomendamos a
          visita periódica desta página para que o Titular de Dados tenha
          conhecimento sobre as modicações efetivadas. Caso sejam feitas
          alterações relevantes que requeiram novas autorizações, iremos
          comunicá-lo.
        </p>

        <h2>12. FORO</h2>
        <p>
          Fica eleito o foro de Curitiba/PR para resolução de qualquer litígio
          ou controvérsia envolvendo esta Política de Privacidade, exceto em
          caso de competência específica determinada em lei.
        </p>

        <p>
          ATENÇÃO! O aceite à esta Política de Privacidade se dará mediante a
          marcação do box correspondente à opção “Aceito a Política de
          Privacidade da LUP”.
        </p>
        <p>
          DESTACAMOS QUE, AO ACEITAR A POLÍTICA DE PRIVACIDADE VOCÊ ESTÁ
          CONCEDENDO SEU CONSENTIMENTO À INTEGRALIDADE DAS DISPOSIÇÕES AQUI
          CONTIDAS, SENDO O ACEITE CONDIÇÃO ABSOLUTA E INAFASTÁVEL PARA A
          CONTRATAÇÃO DA LUP E UTILIZAÇÃO DE SEU WEBSITE/APP.
        </p>
      </div>
      <Footer />
    </>
  );
}

