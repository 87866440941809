import React from "react"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import "./css/index.css"


const ModalConfirmaRecusa = ({title, body, open, close, btnConfirm, btnRefuse})=>{


    return (
    <>
      
        <Modal show={open} onHide={close}>
            <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">{body}</Modal.Body>
            <Modal.Footer>
        
            <Button className="btnModalConfirm" variant="primary" onClick={btnConfirm}>
                Confirmar
            </Button>

            <Button className="btnModalRefuse" variant="primary" onClick={btnRefuse}>
                Cancelar
            </Button>

            </Modal.Footer>
        </Modal>
      </>
      )
}

export default ModalConfirmaRecusa;