import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { formatDate, formatPhoneToNumbers } from "../../../utils/Format";
import ModalConfirmaRecusa from "../ModalConfirmarRecusar/index";
import newBaseApi from "../../../Services/newBaseApi";
import "./css/index.css";
import DefaultUser from "../../../Assets/defaultuser.jpeg";
import Rating from "react-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";

export default function DetalhesEvento() {
  const { id } = useParams();
  const [evento, setEvento] = useState(null);
  const [orcamentos, setOrcamentos] = useState([]);
  const [orcamentosPorRamo, setOrcamentosPorRamo] = useState({});
  const [mostraModal, setMostraModal] = useState({mostra: false, state:"", id: null});

  const [showAvaliacaoModal, setShowAvaliacaoModal] = useState(false);
  const [selectedOrcamento, setSelectedOrcamento] = useState(null);
  const [nota, setNota] = useState(0);
  const [comentario, setComentario] = useState("");

  useEffect(() => {
    fetchData(id);
  }, [id]);

  async function fetchData(id) {
    try {
      const response = await newBaseApi.get(
        `/consumidor/eventos/listOrcamentos/${id}`
      );
      const data = response.data;

      console.log(data.orcamentos);

      setEvento({
        evento_id: data.evento_id,
        nome: data.nome,
        data: data.data,
        inicio: data.inicio,
        fim: data.fim,
        endereco: data.endereco,
        numero: data.numero,
        bairro: data.bairro,
        numero_convidados: data.numero_convidados,
        descricao: data.descricao,
        unicodia: data.unicodia,
        datadoeventofim: data.datadoeventofim,
        cidade: data.cidade,
        estado: data.estado,
      });

      setOrcamentos(data.orcamentos);

      // Agrupar orçamentos por ramo
      const agrupados = data.orcamentos.reduce((acc, orcamento) => {
        const ramo = orcamento.anuncio.ramo || "Outros";
        if (!acc[ramo]) acc[ramo] = [];
        acc[ramo].push(orcamento);
        return acc;
      }, {});
      setOrcamentosPorRamo(agrupados);
    } catch (error) {
      toast.error("Erro ao carregar os dados do evento");
      console.error(error);
    }
  };

  const [showContatoPopup, setShowContatoPopup] = useState(null); // Estado para gerenciar o PopUp

  const handleContatoPopup = (orcamento) => {
    setShowContatoPopup(orcamento); // Exibe as informações do contato
  };

  const closeContatoPopup = () => {
    setShowContatoPopup(null); // Fecha o PopUp
  };

  const handleOpenAvaliacaoModal = (orcamento) => {
    setSelectedOrcamento(orcamento);
    setShowAvaliacaoModal(true);
    setNota(0);
    setComentario("");
  };
  

  const toggleDetalhes = (ramo, index) => {
    const updatedOrcamentos = { ...orcamentosPorRamo };
    updatedOrcamentos[ramo][index].detalhesVisiveis =
      !updatedOrcamentos[ramo][index].detalhesVisiveis;
    setOrcamentosPorRamo(updatedOrcamentos);    
  };

  const alterarStatus = async (orcamentoId, novoStatus) => {
    try {
      await newBaseApi.put("/consumidor/eventos/alterarStatusOrcamento", {
        orcamento_id: orcamentoId,
        status: novoStatus,
      });

      const ramoOrcamentoAlterado = orcamentos.find(
        (orcamento) => orcamento.orcamento_id === orcamentoId)?.anuncio.ramo;

      let updatedOrcamentos = [];

      for (let index = 0; index < orcamentos.length; index++) {
        const orcamento = orcamentos[index];

        if (novoStatus === "aprovado") {
          let objeto = orcamento.orcamento_id === orcamentoId ? { ...orcamento, status: novoStatus } : orcamento.anuncio.ramo === ramoOrcamentoAlterado? { ...orcamento, status: "reprovado" } : orcamento;
          updatedOrcamentos.push(objeto);
        } else {
          let objeto = orcamento.orcamento_id === orcamentoId ? { ...orcamento, status: novoStatus } : orcamento;
          updatedOrcamentos.push(objeto);
        }
      }

      setOrcamentos(updatedOrcamentos);
      toast.success(`Status alterado para ${novoStatus}`);
      fetchData(id);
    } catch (error) {
      toast.error("Erro ao alterar o status do orçamento");
      console.error(error);
    }
  };

  const getStatusClass = (status) => {
    if (status === "aprovado") return "status-aprovado";
    if (status === "reprovado") return "status-reprovado";
    return "status-pendente";
  };

  const handleSubmitAvaliacao = async () => {
    if (!selectedOrcamento || nota === 0) {
      toast.error("Selecione uma nota antes de enviar a avaliação.");
      return;
    }
  
    const avaliacaoData = {
      anunciante_id: selectedOrcamento.anunciante.id,
      evento_id: evento.evento_id,
      nota: nota,
      comentario: comentario,
    };
  
    try {
      await newBaseApi.post("/consumidor/avaliar", avaliacaoData);
      toast.success("Avaliação enviada com sucesso!");
      setShowAvaliacaoModal(false);
    } catch (error) {
      toast.error("Erro ao enviar a avaliação. Tente novamente.");
      console.error(error);
    }
  };
  
  const eventoFinalizado = () => {
    if (!evento) return false;
  
    const hoje = new Date();
    const dataFim = new Date(evento.unicodia === "sim" ? evento.data : evento.datadoeventofim);
  
    // Adiciona 1 dia ao término do evento
    dataFim.setDate(dataFim.getDate() + 1);
  
    return hoje >= dataFim;
  };
  

  return (
    <div className="container">
      <div className="detalhes-container">
        <Link to="/dashboard">
          <button className="back-button">⬅ Voltar para Meus Eventos</button>
        </Link>

        {evento && (
          <section className="evento-detalhes">
            <h1 className="evento-titulo">{evento.nome}</h1>
            <div className="evento-datas">
              {evento.unicodia === "sim" ? (
                <>
                  <div className="data-horario">
                    <span className="data">📅 {formatDate(evento.data)}</span>
                    <span className="horario">
                      🕒 {evento.inicio} - {evento.fim}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="data-horario">
                    <span className="data">
                      📅 Início: {formatDate(evento.data)}
                    </span>
                    <span className="horario">🕒 {evento.inicio}</span>
                  </div>
                  <div className="data-horario">
                    <span className="data">
                      📅 Término: {formatDate(evento.datadoeventofim)}
                    </span>
                    <span className="horario">🕒 {evento.fim}</span>
                  </div>
                </>
              )}
            </div>
            <p>
              <strong>Localização:</strong>{" "}
              {evento.cidade && evento.estado ? (
                <>
                  {evento.endereco && (
                    <>
                      {evento.endereco}
                      {evento.numero && `, ${evento.numero} - `}
                    </>
                  )}
                  {evento.bairro && <>{evento.bairro}, </>}
                  {`${evento.cidade} - ${evento.estado}`}
                </>
              ) : (
                "Localização não informada"
              )}
            </p>

            <p>
              <strong>Número de Convidados:</strong>{" "}
              {evento.numero_convidados || "Não informado"}
            </p>
            <p>
              <strong>Descrição:</strong> {evento.descricao || "Não informado"}
            </p>
          </section>
        )}

        <section className="orcamentos-detalhes">
          <h1 className="orcamentos-titulo">Orçamentos</h1>
          {Object.keys(orcamentosPorRamo).length === 0 ? (
            <p className="no-orcamentos-message">
              Ainda não existem orçamentos disponíveis para este evento.
            </p>
          ) : (
            Object.keys(orcamentosPorRamo).map((ramo) => (
              <div key={ramo} className="ramo-section">
                <h3>{ramo}</h3>
                {orcamentosPorRamo[ramo].map((orcamento, index) => (
                  <div key={orcamento.orcamento_id} className="orcamento-card">
                    <div className="orcamento-header-buttons">
                      <div className="orcamento-header">
                        <p>
                          <img
                            src={orcamento.anuncio?.logo || DefaultUser}
                            className="orcamento-logo"
                            alt="Logo"
                          />
                          <Link
                            to={`/anuncio/${orcamento.anuncio.id}/${orcamento.anunciante.id}`}
                          >
                            {orcamento.anuncio?.nome || "Não informado"}
                          </Link>
                        </p>
                        <p>
                          <strong>Valor Total:</strong> R${" "}
                          {(orcamento.valor_total / 100).toFixed(2)}
                        </p>
                        <p>
                          <strong>Status:</strong>{" "}
                          <span className={getStatusClass(orcamento.status)}>
                            {orcamento.status}
                          </span>
                        </p>
                      </div>

              
                      {orcamento.status === "pendente" && (
                        <div className="action-buttons">
                          <button
                            className="accept-button"
                            onClick={() => {
                              setMostraModal({mostra: true, state: "aprovado", id: orcamento.orcamento_id})
                            }}
                          
                          ></button>
                          <button
                            className="reject-button"
                            onClick={() => setMostraModal({mostra: true, state: "reprovado", id: orcamento.orcamento_id})}
                          ></button>
                        </div>
                      )}
                      <div className="buttons-avalia-contato">
                      {orcamento.status === "aprovado" && eventoFinalizado() && (
                        <div className="avaliar-button-wrapper">
                          <button
                            className="avaliar-button"
                            onClick={() => handleOpenAvaliacaoModal(orcamento)}
                          >
                            <FontAwesomeIcon icon={faStarSolid} className="avaliar-icon" /> Avaliar Fornecedor
                          </button>
                        </div>
                      )}

                      {mostraModal.mostra && (
                        <ModalConfirmaRecusa
                          title={"Aviso"}
                          body={
                            "Você deseja confirmar sua escolha sobre este orçamento?"
                          }
                          open={true}
                          close={() => setMostraModal({mostra: false, state: "", id: null})}
                          btnConfirm={() => {
                            mostraModal.state === "aprovado"?
                            alterarStatus(mostraModal.id, "aprovado") :
                            alterarStatus(mostraModal.id, "reprovado");
                            setMostraModal({mostra: false, state: "", id: null});
                          }}
                          btnRefuse={() => setMostraModal({mostra: false, state: "", id: null})}
                        />
                      )}

                      {orcamento.status === "aprovado" && (
                        <div className="contact-button-wrapper">
                          <button
                            className="contact-button"
                            onClick={() => handleContatoPopup(orcamento)}
                          >
                            Entrar em Contato
                          </button>
                        </div>
                      )}     
                      </div>               
                    </div>
                    {/* PopUp de contato */}
                    {showContatoPopup && (
                      <div className="popup-overlay">
                        <div className="popup-content">
                          <h2>Contato do Anunciante</h2>
                          <p>
                            <strong>E-mail:</strong>{" "}
                            <a
                              href={`mailto:${showContatoPopup.anunciante?.email}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {showContatoPopup.anunciante?.email ||
                                "Não informado"}
                            </a>
                          </p>
                          <p>
                            <strong>Celular:</strong>{" "}
                            {showContatoPopup.anuncio?.telefone ? (
                              <a
                                href={`https://wa.me/${formatPhoneToNumbers(
                                  showContatoPopup.anuncio.telefone
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {showContatoPopup.anuncio.telefone}
                              </a>
                            ) : (
                              "Não cadastrado"
                            )}
                          </p>
                          <button
                            onClick={closeContatoPopup}
                            className="popup-close-button"
                          >
                            Fechar
                          </button>
                        </div>
                      </div>
                    )}
                      {showAvaliacaoModal && (
                        <div className="popup-overlay">
                          <div className="popup-content popup-avaliacao">
                            <h2>Avaliar Fornecedor</h2>
                            <p className="popup-text">Selecione a quantidade de estrelas:</p>

                            <Rating
                              emptySymbol={<FontAwesomeIcon icon={faStarRegular} className="star-icon" />}
                              fullSymbol={<FontAwesomeIcon icon={faStarSolid} className="star-icon filled" />}
                              initialRating={nota}
                              onChange={(value) => setNota(value)}
                              fractions={2}
                            />

                            <textarea
                              placeholder="Deixe um comentário (opcional)"
                              value={comentario}
                              onChange={(e) => setComentario(e.target.value)}
                              className="avaliacao-textarea"
                            />

                            <div className="popup-buttons">
                              <button className="submit-button" onClick={handleSubmitAvaliacao}>
                                Enviar Avaliação
                              </button>
                              <button className="close-button" onClick={() => setShowAvaliacaoModal(false)}>
                                Cancelar
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    <div class="detalhes-button-container">
                      <button
                        onClick={() => toggleDetalhes(ramo, index)}
                        className="detalhes-button"
                      >
                        {orcamento.detalhesVisiveis
                          ? "▲ Ver Menos"
                          : "▼ Ver Orçamento completo"}
                      </button>
                      <div class="detalhes-linha"></div>
                    </div>

                    {orcamento.detalhesVisiveis && (
                      <div className="orcamento-detalhes">
                        <p>
                          <strong>E-mail do Fornecedor:</strong>{" "}
                          {orcamento.anunciante?.email || "Não informado"}
                        </p>
                        <p>
                          <strong>Telefone do Fornecedor:</strong>{" "}
                          {orcamento.anunciante?.telefone || "Não informado"}
                        </p>

                        <div className="itens-orcamento">
                          <h3>Itens do Orçamento</h3>
                          {orcamento.itens.length > 0 ? (
                            <table className="itens-tabela">
                              <thead>
                                <tr>
                                  <th>Nome do Item</th>
                                  <th>Quantidade</th>
                                  <th>Valor Total (R$)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orcamento.itens.map((item, i) => (
                                  <tr key={i}>
                                    <td>{item.description}</td>
                                    <td>{item.quantity}</td>
                                    <td>
                                      {(item.total_amount / 100).toFixed(2)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan="2" className="total-label">
                                    Total dos Itens
                                  </td>
                                  <td className="total-valor">
                                    R${" "}
                                    {(
                                      orcamento.itens.reduce(
                                        (sum, item) => sum + item.total_amount,
                                        0
                                      ) / 100
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          ) : (
                            <p className="no-items-message">
                              Este orçamento não possui itens.
                            </p>
                          )}
                        </div>

                        <p>
                          <strong>Tipo de Pagamento:</strong>{" "}
                          {orcamento.tipo_pagamento
                            ? JSON.parse(orcamento.tipo_pagamento).join(", ")
                            : "Não informado"}
                        </p>

                        <p>
                          <strong>Descrição do Pagamento:</strong>{" "}
                          {orcamento.descricao_pagamento || "Não informado"}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))
          )}
        </section>
      </div>
    </div>
  );
}
