import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";

const QuemSomos = () => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12 col-lg-8 offset-lg-2 text-justify mt-30">
        <h1 className="text-center">Quem Somos</h1>
        <p>
          Um site? Uma plataforma? Um salva-vidas? Um sistema para organizar desde um encontro em um barzinho mega legal até um casamento de uma celebridade mundial? É, acho que esse último descreve bem.
          Queremos fazer parte da sua vida, sendo ela pessoal ou empresarial, e marcar presença! Por isso criamos esse sistema diferenciado para atender as necessidades de organizadores de eventos e de fornecedores de serviços, tudo de uma forma justa e prática.
          Afinal, nosso maior interesse é ajudar a criar eventos inesquecíveis e fazer com que o negócio de nossos parceiros cresça, para que possamos crescer junto com todos vocês!
        </p>

        <h4 className="text-center mt-30">Como funciona?</h4>
      </div>
    </div>

    <div className="row">
      <div className="col-md-8 offset-md-4">
        <div className="row text-center">
          <div className="col-md-3 mt-30">
            <Link to="./quem-somos-consumidor">
              <button className="btn btn-default form-control">Consumidor</button>
            </Link>
          </div>

          <div className="col-md-3 mt-30">
            <Link to="./quem-somos-anunciante">
              <button className="btn btn-default form-control">Anunciante</button>
            </Link>
          </div>
        </div>

        {/* Novo bloco separado para centralizar o botão Tutoriais */}
        <div className="row ">
          <div className="col-md-6 mt-30 text-center">
            <Link to="./tutorial">
              <button className="btn btn-default form-control">Tutoriais</button>
            </Link>
          </div>
        </div>

      </div>
    </div>
  </div>
);

export default QuemSomos;
