import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import reclameAqui from "../../Assets/midiakit_lup_agencia_de_publicidade_ltda_generic_stamp.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcMastercard,
  faCcVisa,
  faCcDinersClub,
  faPix,
} from "@fortawesome/free-brands-svg-icons";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import eloIcon from "../../Assets/elo_icon.svg";

export default function Footer({ noShadow }) {
  return (
    <footer
      style={{ boxShadow: noShadow ? "none" : "0px 2px 22px 0px #a7a7a7" }}
    >
      <div className="footer-container">
        <div className="footer-description">
          <h1>O QUE É O LUP?</h1>
          <p>
            Uma plataforma para criar e realizar qualquer tipo de evento, com
            guias específicos para marinheiros de primeira viagem e liberdade
            para festeiros profissionais. Com a vantagem de te colocar em
            contato com os melhores da sua cidade, de maneira fácil e simples.
          </p>
          <p>Razão Social: LUP AGENCIA DE PUBLICIDADE LTDA </p>
          <p>CNPJ: 31.436.839/0001-09 </p>
        </div>
        <div className="footer-list">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/quem-somos">Quem Somos</Link>
            </li>
            <li>
              <Link to="/app-links">Links para o Aplicativo</Link>
            </li>
            <li>
              <a href="mailto:contato@lup.com.br">Contato</a>
            </li>
            <li>
              <a href="/politica-privacidade/app">Política de Privacidade</a>
            </li>
            <li>
              <a href="/tutorial">Tutoriais</a>
            </li>
          </ul>
        </div>
      </div>

      {/* Selo do ReclameAqui como link */}
      <div className="reclame-aqui-container">
        <a
          href="https://reclameaqui.com.br/rav/BCCJ"
          target="_blank"
          rel="noopener noreferrer"
          className="reclame-aqui-link"
        >
          <img
            src={reclameAqui}
            alt="Selo ReclameAqui"
            className="reclame-aqui-img"
          />
        </a>
      </div>

      <div className="payment-icons">
        <FontAwesomeIcon icon={faCcMastercard} className="fa-icon" />
        <FontAwesomeIcon icon={faCcVisa} className="fa-icon" />
        <FontAwesomeIcon icon={faCcDinersClub} className="fa-icon" />
        <FontAwesomeIcon icon={faPix} className="fa-icon" />
        <FontAwesomeIcon icon={faBarcode} className="fa-icon" />
        <img
          src={eloIcon}
          alt="Elo"
          className="fa-icon elo-icon"
          style={{ width: "48px", height: "auto" }}
        />
      </div>
    </footer>
  );
}

