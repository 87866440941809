import React from "react";
import "./../css/informacoes.css";

export default function Informacoes(props) {
  const { data } = props;
  
  const handleWhatsAppRedirect = () => {
    const phoneNumber = data.contato?.replace(/\D/g, ""); // Remove caracteres não numéricos
    const message = `Olá! Estou entrando em contato com você por meio do seu anúncio ${data.nome} publicado na plataforma Lup.`;
    if (phoneNumber) {
      window.open(
        `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`,
        "_blank"
      );
    } else {
      alert("Telefone do anunciante não disponível.");
    }
  };

  return (
    <div className="informacoes-body">
      <div>
        <h1>Serviços</h1>
        <p>{data.ramo_negocio}</p>
      </div>
      <div className="contato-section">
        <h1>Contato/Telefone</h1>
        <div className="telefone-container">
          {data.contato && (
            <>
              <span className="telefone">📞 {data.contato}</span>
              <button
                className="whatsapp-link"
                onClick={handleWhatsAppRedirect}
              >
                Abrir no WhatsApp
              </button>
            </>
          )}
          {!data.contato && (
            <p className="telefone-nao-disponivel">Telefone não disponível</p>
          )}
        </div>
      </div>
    </div>
  );
}

