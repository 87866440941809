import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./css/index.css";

import fig1 from "../../Assets/fig1.png";
import fig2 from "../../Assets/fig2.png";
import fig3 from "../../Assets/fig3.png";
import fig4 from "../../Assets/fig4.png";
import fig5 from "../../Assets/fig5.png";
import fig6 from "../../Assets/fig6.png";
import fig7 from "../../Assets/fig7.png";
import Footer from "../../components/Footer/Footer";

export default function Tutorial() {
  const history = useHistory();
  return (
    <>
      <div className="tutorial-container">
        <div className="button-div">
          <button
            onClick={() => history.push("/quem-somos")}
            className="voltar-btn"
          >
            ← Voltar para Quem Somos
          </button>
        </div>
        <h1 className="h1-tutorial">📢 Tutorial Lup - Como fazer meu anúncio?</h1>

        <p>
          🚀 Que bom que você está aqui! Significa que quer aumentar sua
          visibilidade e ter uma excelente opção de contato com seus futuros
          clientes.
        </p>

        <h2 className="h2-tutorial">📝 Antes de começar:</h2>
        <ul>
          <li>
            <p>
              📲 Certifique-se de ter baixado o
              <NavLink to="/app-links" className="">
                {" "}
                App <strong>Lup Profissionais</strong>
              </NavLink>{" "}
              para Android ou IOS. Link para a página de dowloads aqui
            </p>
          </li>
          <li>
            <p>
              ✅ Feito seu <strong>cadastro</strong> e <strong>login.</strong>
            </p>
          </li>
          <li>
            <p>
              💳 Adquirido o plano <strong>PRO</strong> ou ter utilizado um
              cupom promocional.
            </p>
          </li>
        </ul>

        <h2>💼 Como fazer meu anúncio na Plataforma LUP?</h2>

        {[
          {
            text: "1️⃣ Abra o Menu no topo superior esquerdo e selecione “Atualizar Anúncio”.",
            img: [fig1, fig2], // Agora aceita mais de uma imagem
          },
          {
            text: "2️⃣ Insira seu logotipo clicando em “Alterar Foto” e preencha todos os dados da sua empresa.",
            img: [fig7], // Mantendo como array para padronizar
          },
          {
            text: "3️⃣ Descreva seus serviços, diferenciais, valores e visão. Agora é a hora de convencer seus clientes!",
            img: [],
          },
          {
            text: "4️⃣ Não esqueça de ativar seu anúncio! Caso contrário, ele não aparecerá nas buscas.",
            img: [fig3],
          },
          { text: "5️⃣ Clique em 'Salvar Anúncio'.", img: [] },
          {
            text: "6️⃣ Volte ao Menu no topo esquerdo e selecione 'Início'.",
            img: [fig4],
          },
          {
            text: "7️⃣ Acesse a aba 'Meu Anúncio' e clique em 'Fotos e Vídeos'.",
            img: [fig5],
          },
          {
            text: "8️⃣ Adicione novas mídias clicando em 'Adicionar nova foto'.",
            img: [],
          },
          {
            text: "9️⃣ Para excluir uma mídia, toque sobre ela e selecione 'Excluir'.",
            img: [fig6],
          },
        ].map((step, index) => (
          <div className="tutorial-step" key={index}>
            <p>{step.text}</p>
            {step.img.length > 0 && (
              <div className="image-container">
                {step.img.map((image, imgIndex) => (
                  <img
                    key={imgIndex}
                    src={image}
                    alt={`Passo ${index + 1} - Imagem ${imgIndex + 1}`}
                    className="tutorial-image"
                  />
                ))}
              </div>
            )}
          </div>
        ))}


        <h2>
          ✅ Parabéns! Seu anúncio está pronto para receber novos clientes! 🎉
        </h2>
      </div>

      <Footer />
    </>
  );
}
