import React, { useState, useEffect } from "react";
import "./listagem.css";
import Footer from "../../../components/Footer/Footer";
import { useHistory, useLocation } from "react-router-dom";
import api from "../../../Services/api";
import newBaseApi from "../../../Services/newBaseApi";
import image from "../../../Services/img";
import { Link } from "react-router-dom";
import placeholder from "../../../Assets/placeholder.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import Rating from "react-rating";


export default function Filtro() {
  const location = useLocation();
  const history = useHistory();

  const [listar, setListar] = useState([]);
  const [estados, setEstados] = useState([]);
  const [estadoEscolhido, setEstadoEscolhido] = useState("");
  const [cidades, setCidades] = useState([]);
  const [escolhaCidade, setEscolhaCidade] = useState("");
  const [tipos, setTipos] = useState([]);
  const [ramo, setRamo] = useState([]);
  const [escolhaTipos, setEscolhaTipos] = useState("");
  const [escolhaRamo, setEscolhaRamo] = useState("");
  const [temas, setTemas] = useState([]);
  const [escolhaTema, setEscolhaTema] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Novo estado para controlar o loading

  // Garantir que a página comece no topo
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Extrair filtros da URL
    const searchParams = new URLSearchParams(location.pathname);
    const filtroEstado = searchParams.get("/filter/estado");
    const filtroCidade = searchParams.get("cidade");
    const filtroTipo = searchParams.get("tipoEvento");
    const filtroRamo = searchParams.get("tipoServico");
    const filtroTema = searchParams.get("tema");

    // Inicializar estados com os valores recebidos
    setEstadoEscolhido(filtroEstado || "");
    setEscolhaCidade(filtroCidade || "");
    setEscolhaTipos(filtroTipo || "");
    setEscolhaRamo(filtroRamo || "");
    setEscolhaTema(filtroTema || "");

    // Carregar dados para os filtros e aplicar a pesquisa
    loadEstados();
    loadTipos();
    loadRamo();
    loadTemas();
    if (filtroEstado) loadCidades(filtroEstado);

    const dados = {
      estado_id: filtroEstado ? [{ id: filtroEstado }] : [],
      cidade_id: filtroCidade ? [{ id: filtroCidade }] : [],
      idservicos: filtroTipo ? [{ id: filtroTipo }] : [],
      idramo: filtroRamo ? [{ id: filtroRamo }] : [],
      idtemaestabelecimento: filtroTema ? [{ id: filtroTema }] : [],
    };

    loadListar(dados);
  }, [location.search]);

  async function loadEstados() {
    const response = await api.get("/estados");
    setEstados(response.data);
  }

  async function loadCidades(estadoId) {
    if (!estadoId) return;
    const response = await api.get(`/cidades/${estadoId}`);
    setCidades(response.data);
  }

  async function loadTipos() {
    const response = await api.get("/tiposeventos");
    setTipos(response.data);
  }

  async function loadTemas() {
    const response = await api.get("/temaestabelecimento");
    setTemas(response.data);
  }

  async function loadRamo() {
    const response = await api.get("/ramo");
    setRamo(response.data);
  }

  async function loadListar(dadoUrl) {
    setIsLoading(true);
    try {
      const response = await newBaseApi.post(
        "/anunciante/buscar/anunciante",
        dadoUrl
      );
      const formattedData = response.data.map((anuncio) => ({
        id: anuncio.anuncio_id,
        nome: anuncio.anuncio_nome,
        descricao: anuncio.anuncio_descricao,
        logo: anuncio.anuncio_logo ? `${anuncio.anuncio_logo}` : null,
        foto: anuncio.anuncio_foto ? `${image}${anuncio.anuncio_foto}` : null,
        ramo: anuncio.ramo_descricao,
        mediaAvaliacoes: anuncio.media_avaliacoes ?? 0, // Média das avaliações
        qtdAvaliacoes: anuncio.qtd_avaliacoes ?? 0, // Quantidade de avaliações
        anunciante: {
          id: anuncio.anunciante?.anunciante_id || "Não informado",
          nome: anuncio.anunciante?.anunciante_nome || "Não informado",
          cidade_id: anuncio.anuncio_cidade || "",
          estado_id: anuncio.anuncio_estado || "",
        },
      }));
  
      setListar(formattedData);
    } catch (error) {
      console.error("Erro ao carregar a lista:", error);
    } finally {
      setIsLoading(false);
    }
  }
  

  function handleSelect(e) {
    const id = e.target.value;
    setEstadoEscolhido(id);
    setEscolhaCidade("");

    if (!id) {
      setCidades([]);
      return;
    }

    loadCidades(id);
  }

  function filtrar(tipo) {
    const queryParams = new URLSearchParams({
      estado: estadoEscolhido || "",
      cidade: escolhaCidade || "",
      tipoServico: escolhaRamo || "",
      tema: "",
      tipoEvento: escolhaTipos,
    });

    const dados = {
      estado_id: estadoEscolhido ? [{ id: estadoEscolhido }] : [],
      cidade_id: escolhaCidade ? [{ id: escolhaCidade }] : [],
      idramo: escolhaRamo ? [{ id: escolhaRamo }] : [],
      tipo: escolhaTipos ? [{ id: escolhaTipos }] : [],
    };

    loadListar(dados);

    history.push(`/filter/${queryParams.toString()}`);
  }

  return (
    <>
      <div id="load-fornecedores" className="fornecedores-container">
        <div className="sidebar">
          <h1>Pesquisar</h1>
          <div className="form-sidebar">
            <select onChange={handleSelect} value={estadoEscolhido}>
              <option value="">Selecione o estado</option>
              {estados.map((estado) => (
                <option value={estado.id} key={estado.id}>
                  {estado.nome}
                </option>
              ))}
            </select>
            <select
              onChange={(e) => setEscolhaCidade(e.target.value)}
              value={escolhaCidade}
            >
              <option value="">Selecione a cidade</option>
              {cidades.map((cidade) => (
                <option value={cidade.id} key={cidade.id}>
                  {cidade.nome}
                </option>
              ))}
            </select>
            <select
              onChange={(e) => setEscolhaTipos(e.target.value)}
              value={escolhaTipos}
            >
              <option value="">Tipo do evento</option>
              {tipos.map((tipo) => (
                <option value={tipo.id} key={tipo.id}>
                  {tipo.descricao}
                </option>
              ))}
            </select>
            <select
              onChange={(e) => setEscolhaRamo(e.target.value)}
              value={escolhaRamo}
            >
              <option value="">Tipo do serviço</option>
              {ramo.map((ramo) => (
                <option value={ramo.descricao} key={ramo.id}>
                  {ramo.descricao}
                </option>
              ))}
            </select>
            <select
              onChange={(e) => setEscolhaTema(e.target.value)}
              value={escolhaTema}
            >
              <option value="">Selecione o tema</option>
              {temas.map((tema) => (
                <option value={tema.id} key={tema.id}>
                  {tema.descricao}
                </option>
              ))}
            </select>
            <button onClick={filtrar}>Pesquisar</button>
          </div>
        </div>
        <div className="main-fornecedores">
          <h1>Resultado da Pesquisa:</h1>
          {isLoading ? ( // Mostra o Loading enquanto os dados estão carregando
            <div className="loading">Carregando...</div>
          ) : (
            <div>
              {listar.length === 0 ? (
                <h1 className="messageError">Nenhum resultado encontrado</h1>
              ) : (
                listar.map((item) => (
                  <div className="card-fornecedores" key={item.id}>
                    <div className="bg-card-fornecedores">
                      <div className="img-card-fornecedores">
                        <Link to={`/anuncio/${item.id}/${item.anunciante.id}`}>
                          <img
                            src={item.logo ? item.logo : placeholder}
                            alt={item.nome}
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="body-card-fornecedores">
                    <h1>{item.nome}</h1>
                    <p>{item.ramo}</p>
                    <p>
                      {item.anunciante.cidade_id && `${item.anunciante.cidade_id}, `}
                      {item.anunciante.estado_id}
                    </p>

                    {/* Exibição das estrelas */}
                    <div className="rating-container">
                      <Rating
                        emptySymbol={<FontAwesomeIcon icon={faStarRegular} className="star-icon" />}
                        fullSymbol={<FontAwesomeIcon icon={faStarSolid} className="star-icon filled" />}
                        initialRating={Math.round(item.mediaAvaliacoes * 2) / 2} // Arredondamento para 0.5 ou 1
                        fractions={2}
                        readonly
                      />
                      <span className="rating-text">
                        ({item.qtdAvaliacoes} avaliações)
                      </span>
                    </div>

                    <div className="link-card-fornecedores">
                      <Link to={`/anuncio/${item.id}/${item.anunciante.id}`}>
                        <span>Saiba mais</span>
                      </Link>
                    </div>
                  </div>

                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
