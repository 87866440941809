import { createContext, useContext, useEffect, useState } from "react";
import { getToken, removeLocalUser, setLocalUser } from "../Services/auth";
import newBaseApi from "../Services/newBaseApi";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Estado para o usuário
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Estado de autenticação

  // Função para realizar login e salvar o token
  const login = async (user) => {
    setLocalUser(user);
    await fetchUser(); // Atualiza o estado do usuário
  };

  // Função para deslogar o usuário
  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
    removeLocalUser();

    const path = window?.location?.pathname;
    if (path.indexOf("dashboard") > -1)
      window.location.replace("/login-consumidor");
  };

  // Função para buscar o usuário utilizando o token
  const fetchUser = async () => {
    try {
      const response = await newBaseApi.get("/consumidor/user"); // Requisição para pegar dados do usuário
      setUser(response.data);
      setIsAuthenticated(true);
    } catch (error) {
      if (error.response?.status === 401) {
        // Token inválido ou expirado
        logout();
      }
    }
  };

  useEffect(() => {
    const token = getToken(); // Pega o token do localStorage
    if (token) {
      fetchUser(); // Busca o usuário com base no token
    }
  }, []); // Executa apenas ao carregar a página

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useUser = () => useContext(AuthContext);
